.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-pitchtoggle-3d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl-pitchtoggle-2d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
}
.mapboxgl-canvas {
  left: 0;
}
.canvas.mapboxgl-canvas {
  left: 0;
  height: 100%;
  width: 100%;
}
.mapboxgl-canvas-container {
  height: 100vh;
}

.mapboxgl-building-selector {
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: auto;
  background-image: url(assets/svg/building-selection.svg);
}

.mapboxgl-process-building {
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: auto;
  background-image: url(assets/svg/ai-button.svg);
}

.map-container-projects {
  position: relative;
  height: 50%;
}